import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Label Format" } }, [_c("text-input", { key: "name", attrs: { "form-item": "", "rules": "required|max:50", "label": "Label Format", "placeholder": "Type Label Format", "max-length": 50, "span": 24 } }), _c("cf-select-input", { key: "template", attrs: { "form-item": "", "rules": "required", "label": "Label Template", "placeholder": "Chooese Label Template", "data-source": _vm.labelTemplate, "source": "source", "source-label": "sourceLabel", "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateLabelFormat",
  data() {
    return {
      labelTemplate: [
        {
          source: "REPORT LABEL Q",
          sourceLabel: "REPORT LABEL Q"
        },
        {
          source: "CUSTOMER LABEL",
          sourceLabel: "CUSTOMER LABEL"
        }
      ]
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateLabelFormat = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-label-format" }, [_c("resource", { attrs: { "name": "cf.label-formats", "api-url": _vm.apiUrl, "redirect-route": "/container-filling/label-formats" } }, [_c("create-label-format")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateLabelFormat
  },
  data() {
    return {
      CreateLabelFormat,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
